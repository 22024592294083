<template>
    <div class="px-2">
        <div>
            <div v-if="galleryImages && galleryImages.length">
                <template v-for="(cat,catI) in galleryImages">

                    <a-collapse expandIconPosition="right" :key="catI" class="mb-2">

                        <a-collapse-panel key="1" :header="cat.title || 'Image'" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;'">

                                <div class="img-alt-container mb-4">
                                

                                    <p style="line-height:1" class="mt-3 mb-1">Image Title</p>
                                    <a-input v-model="cat.title" />

                                    <p style="line-height:1" class="mt-3 mb-1">Image</p>
                                    <ImageBoxSelector v-model="cat.image" :removable="true" />
                                    
                                </div>

                            <a-popconfirm
                                title="Are you sure delete this image?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="removeImage(catI)"
                            >
                                <a href="#"  class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc; border-color:var(--danger); color:var(--danger)">
                                    <a-icon type="minus-circle" /> Remove Image
                                </a>
                            </a-popconfirm>
                            

                        </a-collapse-panel>

                    </a-collapse>
                        
                </template>
            </div>
            <div v-else class="empty-blocks p-3" style="color:#ccc; font-size:14px; text-align:center;">
                No Images Added
            </div>
            <template v-if="galleryImages && galleryImages.length <= 50">
                <div @click="addImage" class="p-3 dF jC aC cursor-pointer no-selection" style="gap:1ch; border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
                    <a-icon type="plus-circle" />Add Image
                </div>
            </template>
            <div v-else class="p-3 dF jC aC fC cursor-pointer no-selection text-center" style="border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
                
                <div class="dF aC" style="gap:1ch;">
                    <a-icon type="stop" /> Gallery limit reached
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import LinkEditor from '@/components/site/LinkEditor'

export default {
    components:{ImageBoxSelector,LinkEditor},
    props:{
        value:{
            type:Array,
            required:true,
            default:[]
        },
    },
    data(){
        return {
            galleryImages:[
                {
                    name:'Image One',
                    image:''
                }
            ]
        }
    },
    computed:{

    },
    methods:{
        addImage(){
            let newBlock = {
                name:'Image',
                image:''
            }
            if (this.limitReached){
                console.error('Limit reached for blocks')
            } else {
                console.log('else from add block ran')
                this.galleryImages.push(newBlock)
            }
        },
        removeImage(i){
            this.galleryImages.splice(i,1)
        }
    },
    watch:{
        galleryImages:{
            handler(val,oldVal){
                return this.$emit('input', val)
            }
        },
        'galleryImages':{
            handler(val){
                console.log('GALLERY IMAGES WATCH', val)
            },
            deep:true
        },
    },
    created(){
        
    },
    mounted(){
        // if (!this.value) this.instance = this.instances[0].value
        if (this.value){
            console.log('CREATED THIS VALUE', this.value)
            this.galleryImages = this.value
        }
    }
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;
        
    }
</style>