<template>
    <!-- <div class="button-editor-container" v-if="false">
        <p style="line-height:1" class="mt-2 mb-1">Link Type</p>
        <a-select v-model="form.type" :options="[{label:'External Link',value:'link'},{label:'Goto Section',value:'section'}]" />

        <p style="line-height:1" class="mt-2 mb-1">Text Label</p>
        <a-input v-model="form.label" />

        <template v-if="form.type === 'link'">
            <p style="line-height:1" class="mt-2 mb-1">External Link (URL)</p>
            <a-input v-model="form.value" />
        </template>
        <template v-else-if="form.type === 'section'">
            <p style="line-height:1" class="mt-2 mb-1">Select a Section/Modal</p>
            <a-select v-model="form.value" :options="pageSectionsOptions" />
        </template>
    </div> -->
    <div>
        <p style="line-height:1" class="mt-2 mb-1">Link to Section/Modal</p>
        <a-select v-model="syncData" :options="pageSectionsOptions" />
    </div>

</template>

<script>
export default {
    props:{
        value:{
            type:String,
            default: ''
        },
    },
    computed:{
        pageSectionsOptions(){
            return this.$store.getters.pageSectionsOptions
        }
    },
    data(){
        return {
            syncData:''
        }
    },
    watch:{
        syncData(val){
            return this.$emit('input', val)
        },
        form:{
            deep:true,
            handler(val,oldVal){
                return this.$emit('input', val)
            }
        }
    },
    created(){
        this.syncData = this.value 
    },
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;
        
    }
</style>